import { Component, Injector, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ApiServer,
  DefaultDropdownSettingsInterface,
  DependencyType,
  DropdownFilterConfiguration,
  DropdownSettingsInterface,
  IDropdownDepend,
  IDropdownStaticCondition,
  ILazyLoadingConf,
  ILazyLoadingDropdown,
  IDropdownChangeSelected,
  LazyLoadingInterface,
} from './dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterableObjectTypes, TypeFilterableObjects } from '../filterable-objects.class';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
  DropdownItemFilterInterface,
  FilterComponentInterface,
  FilterDataObjectTypes,
  OutputOptionsInterface,
} from '../filter.class';
import * as _ from 'lodash';
import {
  EFilterDropdownElements,
  FilterConditionInterface,
  HttpOptionInterface,
  IDependSearch,
  ISetFilter,
} from '../../../../store/filter/filter.model';
import { AppState } from '../../../../store/app.state';
import { ActionsSubject, Store } from '@ngrx/store';
import * as FilterActions from '../../../../store/filter/filter.actions';
import { ofType } from '@ngrx/effects';
import { SqlOperators } from '../advanced-filter/advanced-filter.model';
import { FilterCardDropdownService } from './dropdown.service';

@Component({
  selector: 'filter-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, OnDestroy, FilterComponentInterface {
  outputOptions: OutputOptionsInterface;
  dropdownList: FilterDataObjectTypes[] = [];
  selectedItems: FilterDataObjectTypes[] = [];
  public dropdownObject: FilterableObjectTypes;
  @Input() dropdownSettings: DropdownSettingsInterface;
  @Input() dropdownObjectClass: TypeFilterableObjects;
  @Input() depends: DependencyType;
  @Input() filter: DropdownItemFilterInterface;
  @Input() strictControlForSelected: boolean = false;
  @Input() changeSelectedSubject: Subject<IDropdownChangeSelected> = new Subject<IDropdownChangeSelected>();
  @Input() dependProperties: string[] | null = [];
  @Input() dropdownDepends: IDropdownDepend[] = [];
  @Input() staticConditions: IDropdownStaticCondition[] = [];
  public dropdownLabel: string;
  public elementID: string;
  public searchModel: string = null;
  public defaultSettings: DefaultDropdownSettingsInterface = {
    singleSelection: false,
    text: this.translate.instant('filterCard.dropdown.text'),
    enableCheckAll: true,
    selectAllText: this.translate.instant('filterCard.dropdown.selectAllText'),
    unSelectAllText: this.translate.instant('filterCard.dropdown.unSelectAllText'),
    filterSelectAllText: this.translate.instant('filterCard.dropdown.filterSelectAllText'),
    filterUnSelectAllText: this.translate.instant('filterCard.dropdown.filterUnSelectAllText'),
    searchBy: [],
    maxHeight: 300,
    badgeShowLimit: 1,
    classes: '',
    disabled: false,
    searchPlaceholderText: this.translate.instant('filterCard.dropdown.searchPlaceholderText'),
    showCheckbox: false,
    noDataLabel: this.translate.instant('filterCard.dropdown.noDataLabel'),
    searchAutofocus: true,
    lazyLoading: false,
    labelKey: 'itemName',
    primaryKey: 'id',
    position: 'bottom',
    autoPosition: true,
    enableFilterSelectAll: false,
    selectGroup: false,
    addNewItemOnFilter: false,
    addNewButtonText: this.translate.instant('filterCard.dropdown.addNewButtonText'),
    escapeToClose: true,
    clearAll: true,
    isRequired: false,
    searchMinimumCharacter: 3,
    additionalLiveSearchProperties: [],
    clearSearchOnOpen: true,
  };
  filterListenerConfiguration: DropdownFilterConfiguration[] = [];
  outputSubject: Subject<any>;
  submit: boolean = false;
  isLoading$: boolean = false;
  private filterConfigSorted: boolean = false;
  private searchedData: boolean = false;
  private dropdownSubscribe: Subscription;
  private filterStoreSubscribe: Subscription;
  private filterDependedDropdownDataSubscription: Subscription;
  private dependedConditions: IDependSearch = {};
  private isFirstRequest: boolean = true;
  private rawData: FilterDataObjectTypes[] = [];
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private disabledSubjectSubscription: Subscription;
  public isDisabled: boolean = false;
  public dependedOptionConfiguration = [];
  public lazyLoadingConf: ILazyLoadingConf = {
    totalCount: 0,
    allowNewRequest: false,
    currentPage: 1,
    isDropdownOpened: true,
  };

  constructor(
    private translate: TranslateService,
    private injector: Injector,
    private readonly store: Store<AppState>,
    private readonly storeActions: ActionsSubject,
  ) {}

  ngOnInit(): void {
    this.dropdownObject = this.injector.get<FilterableObjectTypes>(this.dropdownObjectClass);
    this.dropdownObject.setApiServer(_.get(this.dropdownSettings, 'server', ApiServer.NestJS));
    this.dropdownSubscribe = this.dropdownObject
      .getDataObservable()
      .subscribe((payload: FilterDataObjectTypes[] | ILazyLoadingDropdown) => {
        const dataCount: number = _.get(payload, 'totalCount', 0);
        this.isLoading$ = _.get(payload, 'isLoading', false);
        this.lazyLoadingConf.totalCount =
          dataCount > this.lazyLoadingConf.totalCount ? dataCount : this.lazyLoadingConf.totalCount;
        this.lazyLoadingConf.allowNewRequest = _.get(payload, 'allowNewRequest', false);

        const data: object[] = Array.isArray(payload) ? payload : payload.data;
        const dropdownData = this.getDataWithInitialData.call(this, data);
        this.setData(dropdownData.slice());

        if (this.depends !== undefined) {
          this.filterData.call(this, this.dropdownList);
        }

        this.applyFilterCondition();

        if (!this.searchedData && this.dropdownSettings.selectAll) {
          this.selectedItems = [...dropdownData];
          this.publishSelectedItems();
        } else if (!this.searchedData && this.dropdownSettings.defaultSelection) {
          const values: (string | number)[] = this.dropdownSettings.defaultSelection.values;
          const key = this.dropdownSettings.defaultSelection.key;
          this.selectedItems = _.clone(dropdownData).filter((item) => {
            if (item.hasOwnProperty(key)) {
              // Don't use type check (===) here.
              // eslint-disable-next-line eqeqeq
              return values.some((x: string | number) => x == item[key]);
            }
            return false;
          });

          if (_.get(this.dropdownSettings, 'defaultSelection.overrideData', false)) {
            this.setData(_.clone(this.selectedItems));
          }

          this.publishSelectedItems();
        }
      });

    this.filterDependedDropdownDataSubscription = this.storeActions
      .pipe(ofType(FilterActions.FilterActionTypes.FilterDependedDropdownData))
      .subscribe(() => {
        this.filterDependedDropdownData();
      });

    this.dropdownObject.init();

    this.defaultSettings = {
      ...this.defaultSettings,
      ...this.dropdownSettings,
      labelKey: this.dropdownObject.getObjectNameProp(),
    };

    if (this.defaultSettings.lazyLoading) {
      this.defaultSettings.classes = this.defaultSettings.classes
        ? `${this.defaultSettings.classes} lazy-loading-dropdown`
        : 'lazy-loading-dropdown';
    }

    this.dropdownLabel = _.cloneDeep(this.defaultSettings.text);

    this.defaultSettings.text =
      !this.dropdownSettings.isRequired && !this.dropdownSettings.singleSelection
        ? `${this.defaultSettings.text}: ${this.translate.instant('filterCard.dropdown.any')}`
        : this.defaultSettings.text;

    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus: boolean) => {
      this.isDisabled = disabledStatus;
    });

    if (this.defaultSettings?.changeSelectedSubject) {
      this.defaultSettings?.changeSelectedSubject?.asObservable().subscribe((payload: IDropdownChangeSelected) => {
        const values: (string | number)[] = payload.values;
        const key = payload.key;
        this.selectedItems = _.cloneDeep(this.dropdownList).filter((item) => {
          if (item.hasOwnProperty(key)) {
            // Don't use type check (===) here.
            // tslint:disable-next-line:triple-equals
            return values.some((x: string | number) => x == item[key]);
          }
          return false;
        });

        if (_.get(this.dropdownSettings, 'defaultSelection.overrideData', false)) {
          this.dropdownList = _.clone(this.selectedItems);
        }

        this.publishSelectedItems();
      });
    }


    if (this.dropdownDepends) {
      this.subscribeFilterStore();
    }

    if (this.defaultSettings.getInitData) {
      this.getInitData();
    }
  }

  public onOpen(): void {
    this.lazyLoadingConf.isDropdownOpened = true;

    this.getInitData();
  }

  public onItemSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onItemDeselect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onSelectAll(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onGroupSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onGroupDeSelect(): void {
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public onDeSelectAll(): void {
    this.selectedItems = [];
    this.publishSelectedItems();
    this.publishValue(this.selectedItems);
  }

  public fetchMore(event: LazyLoadingInterface): void {
    if (
      this.isLoading$ ||
      event.endIndex !== this.dropdownList.length - 1 ||
      !this.lazyLoadingConf.allowNewRequest ||
      event.endIndex === -1
    ) {
      return;
    }

    this.lazyLoadingConf.currentPage = this.lazyLoadingConf.currentPage + 1;
    this.getData(this.lazyLoadingConf.currentPage, true);
  }

  private applyFilterCondition(): void {
    if (!this.filter) return;
    if (this.filter.preCondition && !this.filter.preCondition(this.dropdownList)) return;

    this.dropdownList = this.dropdownList.filter(this.filter.condition);
  }

  public filterData(dropdownList = null): void {
    if (this.dropdownObject === undefined) {
      return;
    }

    this.dropdownList = dropdownList;

    if (dropdownList === null) {
      this.dropdownList = this.dropdownObject.getInitialData();
      this.applyFilterCondition();
    }

    if (!this.filterConfigSorted) {
      this.filterListenerConfiguration.sort((a, b) => {
        return a.submit === b.submit ? 0 : !a.submit ? -1 : 1;
      });
      this.filterConfigSorted = true;
    }
  }

  public publishSelectedItems(selectedItems: FilterDataObjectTypes[] = this.selectedItems): void {
    this.publishSelectedItemsToFilterStore();

    this.outputSubject.next({ ...this.getFiltersOutputs(), showUpdate: true });
    this.filterListenerConfiguration.forEach((config) => {
      if (config.submit) {
        config.filterListener.next(selectedItems);
      }
    });
  }

  public getFiltersOutputs(): any {
    const output = {};
    const isSearchedData = this.searchModel !== null && this.searchModel.length > 0;

    if (this.dropdownSettings.isRequired && this.selectedItems.length === 0) {
      output[this.outputOptions.filterObjectId] = null;
      return output;
    }

    if (
      !isSearchedData &&
      (this.selectedItems.length === 0 ||
        (this.selectedItems.length === this.dropdownList.length && !this.strictControlForSelected))
    ) {
      output[this.outputOptions.filterObjectId] = -1;
      return output;
    }

    if (this.outputOptions.returnFilterObjectAllProp) {
      output[this.outputOptions.filterObjectId] = this.selectedItems;
      return output;
    }

    output[this.outputOptions.filterObjectId] = this.selectedItems.map(
      (item) => item[this.outputOptions.filterObjectProp],
    );
    return output;
  }

  public onSearch(search: string): void {
    if (search.length !== 0 && search.length < this.defaultSettings.searchMinimumCharacter) {
      return;
    }

    if (
      this.defaultSettings.lazyLoading &&
      this.dropdownList.length > 0 &&
      this.selectedItems.length === 0 &&
      search.length === 0 &&
      this.lazyLoadingConf.isDropdownOpened
    ) {
      this.lazyLoadingConf.isDropdownOpened = false;
    }

    this.searchedData = true;
    this.lazyLoadingConf.currentPage = 1;
    this.getData(1, false, search);
  }

  private getDataWithInitialData(data: FilterDataObjectTypes[]): FilterDataObjectTypes[] {
    if (_.get(this.dropdownSettings, 'defaultSelection.initialData', false)) {
      return this.dropdownObject.getDataWithInitialData(this.dropdownSettings.defaultSelection.initialData);
    }

    return data;
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((configuration) => {
      if (this.elementID === configuration?.dependedElementId && this.hasOwnProperty(configuration?.dependedOption)) {
        this[configuration?.dependedOption] = configuration?.getDependentValue(configuration?.value);
      }
    });
  }

  public publishValue(value: FilterDataObjectTypes[]): void {
    this.dependedOptionConfiguration.forEach((config) => {
      if (config.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  private getData(page: number, isFetchMoreRequest: boolean = false, searchInput?: string): void {
    const staticConditions: object[] = FilterCardDropdownService.getStaticConditions(this.staticConditions);
    const option: HttpOptionInterface = { page };
    const conditions: {
      $and: object[];
    } = {
      $and: [],
    };

    if (searchInput) {
      const orFilter: object[] = [];

      FilterCardDropdownService.generateConditionObject(searchInput, this.dropdownSettings.searchProps).map(
        (condition: FilterConditionInterface) => {
          orFilter.push({ [condition.prop]: { [condition.condition]: searchInput } });
        },
      );

      conditions.$and.push({ $or: orFilter });
    }

    if (Object.keys(this.dependedConditions).length > 0) {
      conditions.$and.push(this.dependedConditions);
    }

    if (staticConditions.length > 0) {
      conditions.$and = conditions.$and.concat(staticConditions);
    }

    option.s = JSON.stringify(conditions);

    this.isLoading$ = true;
    this.dropdownObject.getFilterData(option, isFetchMoreRequest, searchInput);
  }

  private publishSelectedItemsToFilterStore(): void {
    if (!this.dependProperties?.length) {
      return;
    }

    this.publishAvailableDataForDependedDropdowns();
  }

  private publishAvailableDataForDependedDropdowns(): void {
    for (const property of this.dependProperties) {
      const availableItems: FilterDataObjectTypes[] = this.selectedItems.length
        ? this.selectedItems
        : this.dropdownList;
      const values: number[] = availableItems.map((item: FilterDataObjectTypes) => _.get(item, property));

      this.store.dispatch(
        new FilterActions.SetFilter(
          this.elementID as EFilterDropdownElements,
          { property, values },
          !!this.selectedItems.length,
        ),
      );
    }
  }

  private subscribeFilterStore(): void {
    this.filterStoreSubscribe = this.storeActions
      .pipe(ofType(FilterActions.FilterActionTypes.SetFilter))
      .subscribe((data: ISetFilter) => {
        if (!this.dropdownDepends) {
          return;
        }

        for (const depend of this.dropdownDepends) {
          if (depend.parentElementId === data.elementId && depend.parentProperty === data.value.property) {
            const values: number[] = data.value.values as number[];
            FilterCardDropdownService.setDependedConditions(
              values,
              depend,
              this.dependedConditions,
              data.isItemSelected,
              depend.isAllSelectedAndNoneSelectedDifferent,
            );
            this.filterDependedDropdownData();
          }
        }
      });
  }

  private filterDependedDropdownData(): void {
    if (!this.dependedConditions || this.dropdownDepends.length === 0) {
      return;
    }

    for (const depend of this.dropdownDepends) {
      const condition: { [key in SqlOperators]: number[] | string[] } = this.dependedConditions[depend.property];

      if (condition && condition.hasOwnProperty(SqlOperators.$in)) {
        const selectedParentIds: number[] = FilterCardDropdownService.getSelectedParentIds(condition, depend);
        const isClientSideSearch: boolean = !this.defaultSettings.enableServerSideSearch;

        this.dropdownList = isClientSideSearch
          ? FilterCardDropdownService.filterClientSideDropdownAllData(this.rawData, depend, selectedParentIds)
          : this.dropdownList;

        this.filterSelectedItems(this.selectedItems, depend, selectedParentIds);

        if (isClientSideSearch) {
          continue;
        }
      }

      if (!Object.keys(this.dependedConditions).length) {
        this.dropdownList = this.rawData;
      }
    }
  }

  private setData(data: FilterDataObjectTypes[]): void {
    this.rawData = _.cloneDeep(data);
    this.dropdownList = data;

    if (this.dropdownDepends) {
      this.filterDependedDropdownData();
    }
  }

  private getInitData(): void {
    const isAvailableForGetAllData: boolean =
      !this.defaultSettings.enableServerSideSearch && this.isFirstRequest && !this.defaultSettings.isStaticDropdown;

    if (!isAvailableForGetAllData) {
      return;
    }

    this.isFirstRequest = false;
    this.isLoading$ = true;
    const conditions: {
      $and: object[];
    } = {
      $and: FilterCardDropdownService.getStaticConditions(this.staticConditions),
    };
    const options: HttpOptionInterface = conditions.$and.length > 0 ? { s: JSON.stringify(conditions) } : undefined;

    this.dropdownObject.getAllData(options);
  }

  private filterSelectedItems(
    data: FilterDataObjectTypes[],
    depend: IDropdownDepend,
    selectedParentIds: number[],
  ): void {
    this.selectedItems = FilterCardDropdownService.filterClientSideDropdownAllData(data, depend, selectedParentIds);
    this.publishAvailableDataForDependedDropdowns();
    this.outputSubject.next({ ...this.getFiltersOutputs(), showUpdate: false });
    this.filterListenerConfiguration.forEach((config) => {
      if (config.submit) {
        config.filterListener.next(this.selectedItems);
      }
    });

    this.dependedOptionConfiguration.forEach((config) => {
      if (config.submit) {
        config.dependedOptionListener.next({
          ...config,
          value: this.selectedItems,
        });
      }
    });
  }

  public onClose(): void {
    if (this.defaultSettings.lazyLoading && this.defaultSettings.enableServerSideSearch) {
      this.dropdownList = [];
    }
  }

  ngOnDestroy(): void {
    if (this.dropdownObject.subscribe !== null) {
      this.dropdownObject.subscribe.unsubscribe();
    }

    if (this.filterStoreSubscribe) {
      this.filterStoreSubscribe.unsubscribe();
    }

    this.dropdownSubscribe.unsubscribe();
    this.dropdownObject.destroy();
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();
    this.filterDependedDropdownDataSubscription?.unsubscribe();
  }
}
