import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DefaultSelectionValuesInterface,
  FilterCardOptionInterface,
  SiteCRUDInterface,
} from '../../../shared/component/filter/filter.class';
import { EFilterDropdownElements } from '../../../store/filter/filter.model';
import {
  Equipment,
  FilterCardUser,
  Level,
  Line,
  Site,
} from '../../../shared/component/filter/filterable-objects.class';
import { DropdownComponent } from '../../../shared/component/filter/dropdown/dropdown.component';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { HelperService } from '../../../shared/service/helper.service';
import { FilterHelperService } from '../../../shared/service/filter/filter.helper.service';
import { ofType } from '@ngrx/effects';
import { combineLatest, filter, Subject, Subscription, take, takeUntil } from 'rxjs';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import { ButtonGroupComponent } from '../../../shared/component/filter/button-group/button-group.component';
import { ScwMatButtonGroupButtons } from '../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import {
  ECheckInOperationTypes,
  ELaborAssetViewDataType,
  ELaborAssetViewType,
  EQueryType,
  IAvailableModule,
  ILaborAssetViewFilter,
} from './labor-asset-view.model';
import * as PageHeaderActions from '../../../store/page-header/page-header.actions';
import * as AppActions from '../../../store/app/actions';
import * as _ from 'lodash';
import { SqlOperators } from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { User, UserLevels } from '../../../store/user/model';
import { FilterSiteState } from '../../../store/filter/site/site.reducer';
import * as LaborAssetViewActions from '../../../store/dashboards/labor-asset-view/labor-asset-view.actions';
import {
  ILaborAssetViewData,
  ILaborAssetViewState,
} from '../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import { ITableHeader, PageConfigurationTypes } from '../../../../constants.model';
import { EColumnWidth } from '../../../shared/service/datatable/datatable.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../shared/component/page-configuration/page-configuration.model';
import { LaborAssetViewService } from '../../../store/dashboards/labor-asset-view/labor-asset-view.service';
import { GenericHelperService } from '../../../shared/service/generic.helper.service';
import {
  ComponentNamesForUserConfiguration,
  IComponentConfiguration,
  IUserConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import * as UserConfigurationActions from '../../../store/user-configuration/user-configuration.actions';
import { FilterLineState } from '../../../store/filter/line/line.reducer';
import { SourceObjectFilterState } from '../../../store/filter/check-in-log-source-object/source-object.reducer';
import { FilterEquipmentState } from '../../../store/filter/equipment/equipment.reducer';
import { expandCollapseButtons } from './labor-asset-view-card-view/labor-asset-view.constants';
import * as CheckInActions from '../../../store/check-in/check-in.actions';
import { SignalrBroadcastInterface } from '../../../shared/service/signalr/signalr.model';
import { MessageAction } from '../../../shared/model/enum/message-action';
import { SignalRService } from '../../../shared/service/signalr/signalr.service';
import * as signalR from '@microsoft/signalr';

@OnDestroyDecorator
@Component({
  selector: 'scw-labor-asset-view',
  templateUrl: './labor-asset-view.component.html',
  styleUrls: ['./labor-asset-view.component.scss'],
})
export class LaborAssetViewComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  private readonly filterOptionsCommonClass: string = 'col-xl-3 col-lg-4 col-md-3 m-t-5 m-b-5 ';
  private readonly filterNamesToReset: string[] = [
    'typeToggle',
    EFilterDropdownElements.equipmentMultiSelectDropdown,
    EFilterDropdownElements.userMultiSelectDropdown,
    EFilterDropdownElements.userLevelMultiSelectDropdown,
  ];
  private defaultUserConfiguration: IComponentConfiguration[] = [];
  private hiddenFiltersDefaultSelectionValues!: DefaultSelectionValuesInterface;
  private isLaborAssetViewDataLoading$: boolean = false;
  private subscribedSiteGroup!: number;
  private connectionIdSubscription: Subscription;
  private homeLineId$: number | undefined;
  private connectionStopped: boolean = false;
  public readonly expandCollapseButtons: ScwMatButtonGroupButtons[] = expandCollapseButtons;
  public readonly filterOptions: FilterCardOptionInterface = {
    rows: [
      [
        {
          type: DropdownComponent,
          cls: this.filterOptionsCommonClass,
          object: Site,
          elementId: EFilterDropdownElements.siteSingleSelectDropdown,
          outputOptions: {
            filterObjectId: 'site',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            text: this.translate.instant('filterCard.site.dropdownPlaceHolder'),
            isRequired: true,
            singleSelection: true,
          },
        },
        {
          type: DropdownComponent,
          cls: this.filterOptionsCommonClass,
          object: Line,
          elementId: EFilterDropdownElements.lineMultiSelectDropdown,
          outputOptions: {
            filterObjectId: 'lines',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            text: this.translate.instant('filterCard.line.dropdownPlaceHolder'),
            isRequired: false,
            getInitData: true,
          },
        },
        {
          elementId: EFilterDropdownElements.userMultiSelectDropdown,
          type: DropdownComponent,
          cls: this.filterOptionsCommonClass,
          object: FilterCardUser,
          outputOptions: {
            filterObjectId: 'userIds',
            filterObjectProp: 'sourceObjectId',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            limitSelection: 10,
            text: this.translate.instant('filterCard.user.dropdownPlaceHolder'),
            noDataLabel: this.translate.instant('filterCard.dropdown.labelForUserDepends'),
            primaryKey: 'sourceObjectId',
            getInitData: true,
          },
          dropdownDepends: [
            {
              property: 'destinationObjectId',
              parentElementId: EFilterDropdownElements.lineMultiSelectDropdown,
              parentProperty: 'id',
              conditionType: SqlOperators.$in,
              isDataLocatedAtCurrent: true,
            },
          ],
        },
        {
          type: DropdownComponent,
          cls: this.filterOptionsCommonClass,
          object: Level,
          filter: {
            condition: (element: { id: UserLevels }): boolean => element.id !== UserLevels.PLANNER,
          },
          elementId: EFilterDropdownElements.userLevelMultiSelectDropdown,
          outputOptions: {
            filterObjectId: 'userTypes',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            text: this.translate.instant('filterCard.userLevel.dropdownPlaceHolder'),
            isRequired: false,
            isStaticDropdown: true,
            getInitData: true,
          },
        },
        {
          elementId: EFilterDropdownElements.equipmentMultiSelectDropdown,
          type: DropdownComponent,
          cls: this.filterOptionsCommonClass,
          object: Equipment,
          outputOptions: {
            filterObjectId: 'assetIds',
            filterObjectProp: 'id',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            enableServerSideSearch: true,
            clearSearchOnOpen: true,
            searchProps: [
              {
                prop: 'equipmentName',
                condition: '$cont',
              },
            ],
            badgeShowLimit: 1,
            text: this.translate.instant('filterCard.asset.dropdownPlaceHolder'),
            noDataLabel: this.translate.instant('filterCard.dropdown.labelForEquipmentDepends'),
            getInitData: true,
          },
          dependProperties: ['id'],
          dropdownDepends: [
            {
              property: 'siteId',
              parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
              parentProperty: 'id',
              conditionType: SqlOperators.$in,
            },
          ],
        },
        {
          type: ButtonGroupComponent,
          cls: this.filterOptionsCommonClass.concat('button-group-flex'),
          elementId: 'timeToggle',
          outputOptions: {
            filterObjectId: 'timeToggle',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            buttons: [
              {
                text: this.translate.instant('general.ongoing'),
                value: EQueryType.ONGOING,
              },
              {
                text: this.translate.instant('general.last24Hours'),
                value: EQueryType.LAST_24_HOURS,
              },
            ],
            value: EQueryType.ONGOING,
          },
        },
        {
          type: ButtonGroupComponent,
          cls: this.filterOptionsCommonClass.replace('lg-4', 'lg-6').concat('button-group-flex'),
          elementId: 'typeToggle',
          outputOptions: {
            filterObjectId: 'typeToggle',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            buttons: [
              {
                text: this.translate.instant('general.all'),
                value: ELaborAssetViewDataType.ALL,
              },
              {
                text: this.translate.instant('schedulerScenario.laborSchedule.button.title'),
                value: ELaborAssetViewDataType.LABOR,
              },
              {
                text: this.translate.instant('globalView.infoCard.asset'),
                value: ELaborAssetViewDataType.ASSET,
              },
            ],
            value: ELaborAssetViewDataType.ALL,
          },
        },
        {
          type: ButtonGroupComponent,
          cls: this.filterOptionsCommonClass.replace('lg-4', 'lg-6').concat('button-group-flex'),
          elementId: 'viewTypeToggle',
          outputOptions: {
            filterObjectId: 'viewTypeToggle',
            returnFilterObjectAllProp: false,
          },
          options: {
            isRequired: false,
            buttons: [
              {
                text: this.translate.instant('laborAssetView.pageViewTypes.listView'),
                value: ELaborAssetViewType.LIST_VIEW,
              },
              {
                text: this.translate.instant('laborAssetView.pageViewTypes.cardView'),
                value: ELaborAssetViewType.CARD_VIEW,
              },
            ],
            value: ELaborAssetViewType.CARD_VIEW,
          },
        },
      ],
    ],
  };
  public readonly laborAssetViewTypes: typeof ELaborAssetViewType = ELaborAssetViewType;
  public currentFilters!: ILaborAssetViewFilter;
  public filterCardHiddenIds: string[] = [];
  public resetFilterCardHiddenItems: Subject<void> = new Subject<void>();
  public pageViewTypeValue: ELaborAssetViewType = ELaborAssetViewType.CARD_VIEW;
  public isFilterBarVisible$: boolean = false;
  public defaultDropdownSelectionSubject: Subject<DefaultSelectionValuesInterface> =
    new Subject<DefaultSelectionValuesInterface>();
  public searchBoxText: string = '';
  public selectedSite!: SiteCRUDInterface;
  public laborAssetViewData$: ILaborAssetViewData[] = [];
  public filteredLaborAssetViewData: ILaborAssetViewData[] = [];
  public laborAssetViewDatatableHeaders$: ITableHeader[] = [];
  public laborAssetViewDatatableHeaders: ITableHeader[] = [];
  public laborAssetViewWidgets$: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [];
  public isLaborAssetViewTableCheckboxVisible: boolean = false;
  public isCardViewExpanded: boolean = !this.genericHelperService.isTouchDevice;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly storeActions: ActionsSubject,
    private readonly filterHelperService: FilterHelperService,
    private readonly laborAssetViewService: LaborAssetViewService,
    private readonly genericHelperService: GenericHelperService,
    private readonly signalRService: SignalRService,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new AppActions.ShowLoader());

    this.subscriptions.push(
      this.signalRService.broadcastMessage.subscribe((broadcastData: SignalrBroadcastInterface): void => {
        const isLineIncluded: boolean =
          this.currentFilters.lines === -1 ||
          this.currentFilters.lines.includes(_.get(broadcastData.additionalData, 'targetLine', null));
        const operationType: string | null = _.get(broadcastData.additionalData, 'operationType', null);
        const isUpdate: boolean = operationType === ECheckInOperationTypes.UPDATE_STATUS;
        const isCheckout: boolean = operationType === ECheckInOperationTypes.CHECK_OUT;

        if (
          [MessageAction.SITE_CHECK_IN_LIST, MessageAction].includes(broadcastData.message) &&
          ((!this.isLaborAssetViewDataLoading$ && (isLineIncluded || isUpdate)) || isCheckout)
        ) {
          this.reloadData();
          this.store.dispatch(new PageHeaderActions.CountdownReset());
        }
      }),

      this.store.select('laborAssetViewStore').subscribe((state: ILaborAssetViewState): void => {
        this.isLaborAssetViewDataLoading$ = state.laborAssetViewDataLoading && !state.laborAssetViewDataLoaded;
      }),

      this.storeActions
        .pipe(ofType(PageHeaderActions.UPDATE_FILTER_BAR_VISIBILITY))
        .subscribe((state: PageHeaderActions.UpdateFilterBarVisibility): void => {
          this.isFilterBarVisible$ = state.isVisible;
        }),

      this.storeActions.pipe(ofType(PageHeaderActions.COUNTDOWN_TIMEOUT)).subscribe((): void => {
        this.reloadData();
      }),

      this.storeActions
        .pipe(ofType(LaborAssetViewActions.ELaborAssetHoursActionTypes.LABOR_ASSET_VIEW_DATA_LOADED))
        .subscribe((response: LaborAssetViewActions.LaborAssetViewDataLoaded): void => {
          this.laborAssetViewData$ = this.filteredLaborAssetViewData = response.payload;

          this.onKeyUpSearchBox(this.searchBoxText);

          this.store.dispatch(new PageHeaderActions.UpdatePageMode(true));
          this.store.dispatch(new PageHeaderActions.CountdownReset());
        }),

      this.storeActions
        .pipe(ofType(LaborAssetViewActions.ELaborAssetHoursActionTypes.SET_TABLE_SETTINGS))
        .subscribe((response: LaborAssetViewActions.SetTableSettings): void => {
          this.onApplyClick(response);
        }),

      this.storeActions
        .pipe(ofType(CheckInActions.CHECK_OUT_DATA_LOADED, CheckInActions.CHECK_OUT_UPDATE_ONGOING_LOADED))
        .subscribe((): void => {
          this.store.dispatch(new AppActions.HideLoader());

          this.reloadData();
        }),

      this.store.select('user').subscribe((user: User): void => {
        this.homeLineId$ = user.lineId;
      }),
    );
  }

  public ngAfterViewInit(): void {
    const destroySubject: Subject<void> = new Subject<void>();

    this.store
      .select('userConfigurationStore')
      .pipe(
        takeUntil(destroySubject),
        filter(
          (state: UserConfigurationStateInterface) =>
            !state.userConfigurationDataLoading && state.userConfigurationDataLoaded,
        ),
      )
      .subscribe((state: UserConfigurationStateInterface): void => {
        const hasUserConfiguration: boolean = !!_.find(state?.userConfigurationData?.LaborAssetViewComponent, {
          name: 'filters',
        });
        this.defaultUserConfiguration = state?.userConfigurationData?.LaborAssetViewComponent || [];

        if (!hasUserConfiguration) {
          this.filterHelperService.setDefaultSiteAndPushSubject(this.defaultDropdownSelectionSubject);

          this.store.dispatch(new AppActions.HideLoader());
        } else {
          this.performUserConfigurationOperations(HelperService.cloneDeep(state));
        }

        destroySubject.next();
      });
  }

  private async addToSignalRGroup(siteId: number): Promise<void> {
    try {
      if (
        !this.signalRService.hubConnection.connectionId &&
        // @ts-ignore
        this.signalRService.hubConnection.connectionState !== signalR.HubConnectionState.Connecting
      ) {
        await this.signalRService.startConnection();
      }

      this.signalRService.addToGroup('site', siteId);

      if (this.connectionStopped && this.homeLineId$) {
        this.signalRService.addToGroup('line', this.homeLineId$);

        this.connectionStopped = false;
      }
    } catch (error) {
      const threeSeconds: number = 3000;
      setTimeout(() => this.signalRService.startConnection(), threeSeconds);
    }
  }

  private async triggerSignalRReconnect(): Promise<void> {
    this.connectionStopped = true;

    this.signalRService.connectionIdSubject.next(null);
    await this.signalRService.hubConnection.stop();
    await this.signalRService.startConnection();
    this.invokeAddToSignalRGroupAndUnsubscribe();
  }

  private invokeAddToSignalRGroupAndUnsubscribe(): void {
    this.connectionIdSubscription = this.signalRService.connectionIdSubject.subscribe((connectionId: string) => {
      if (connectionId && this.subscribedSiteGroup !== this.selectedSite.id) {
        this.addToSignalRGroup(this.selectedSite.id);

        this.subscribedSiteGroup = this.selectedSite.id;

        if (this.connectionIdSubscription) {
          this.connectionIdSubscription.unsubscribe();
        }
      }
    });
  }

  public onFiltersChanged(filterState: ILaborAssetViewFilter): void {
    if (!this.filterHelperService.isDefaultSelectionPushed) {
      return;
    }

    const selectedSiteId: number = _.get(filterState.site, '0', null);
    const firstSiteSelection: boolean = !this.selectedSite;
    const siteChanged: boolean = this.selectedSite && this.selectedSite.id !== selectedSiteId;
    let currentPageMode: ELaborAssetViewDataType = LaborAssetViewService.pageModeSubject.getValue();
    let availableModulesChanged: boolean = false;
    const changedKeys: string[] = [];

    Object.entries(this.currentFilters || {}).forEach(([key, value]): void => {
      if (filterState[key] !== value) {
        changedKeys.push(key);
      }
    });

    const preventFetch: boolean =
      !!this.currentFilters &&
      (changedKeys.length === 0 || (changedKeys.length === 1 && changedKeys[0] === 'viewTypeToggle'));

    this.currentFilters = filterState;
    this.pageViewTypeValue = filterState.viewTypeToggle;

    this.store
      .select('siteFilter')
      .pipe(take(1))
      .subscribe((siteState: FilterSiteState): void => {
        this.selectedSite = _.find(siteState.data, { id: selectedSiteId });
        const availableModules: IAvailableModule = {
          laborTracker: this.selectedSite.menuPermissions?.modules?.laborTracker,
          assetTracker: this.selectedSite.menuPermissions?.modules?.assetTracker,
        };

        if (availableModules.laborTracker === availableModules.assetTracker) {
          availableModulesChanged = currentPageMode !== ELaborAssetViewDataType.ALL;
          currentPageMode = ELaborAssetViewDataType.ALL;

          LaborAssetViewService.pageModeSubject.next(ELaborAssetViewDataType.ALL);
        } else if (availableModules.laborTracker && !availableModules.assetTracker) {
          availableModulesChanged = currentPageMode !== ELaborAssetViewDataType.LABOR;
          currentPageMode = ELaborAssetViewDataType.LABOR;

          LaborAssetViewService.pageModeSubject.next(ELaborAssetViewDataType.LABOR);
        } else if (!availableModules.laborTracker && availableModules.assetTracker) {
          availableModulesChanged = currentPageMode !== ELaborAssetViewDataType.ASSET;
          currentPageMode = ELaborAssetViewDataType.ASSET;

          LaborAssetViewService.pageModeSubject.next(ELaborAssetViewDataType.ASSET);
        }
      });

    if (siteChanged) {
      this.triggerSignalRReconnect();
    } else if (firstSiteSelection) {
      this.invokeAddToSignalRGroupAndUnsubscribe();
    }

    if (availableModulesChanged) {
      this.setPageConfiguration();
      this.setFiltersAndPageTitle();
    }

    switch (currentPageMode) {
      case ELaborAssetViewDataType.LABOR:
        this.currentFilters.assetIds = -1;
        this.currentFilters.typeToggle = ELaborAssetViewDataType.ALL;

        break;
      case ELaborAssetViewDataType.ASSET:
        this.currentFilters.userIds = -1;
        this.currentFilters.selectedLevelId = -1;
        this.currentFilters.typeToggle = ELaborAssetViewDataType.ALL;

        break;
    }

    if (this.pageViewTypeValue === ELaborAssetViewType.LIST_VIEW) {
      this.laborAssetViewDatatableHeaders = _.cloneDeep(this.laborAssetViewDatatableHeaders$);
    }

    if (!preventFetch) {
      this.store.dispatch(new LaborAssetViewActions.LaborAssetViewDataLoading(this.currentFilters));
    }
  }

  public prepareDefaultFilters(filterState: ILaborAssetViewFilter): void {
    const filters: IComponentConfiguration[] = [
      {
        name: 'site',
        value: filterState.site,
      },
      ...(filterState.lines !== -1
        ? [
            {
              name: 'lines',
              value: filterState.lines,
            },
          ]
        : []),
      ...(filterState.userIds !== -1
        ? [
            {
              name: 'userIds',
              value: filterState.userIds,
            },
          ]
        : []),
      ...(filterState.userTypes !== -1
        ? [
            {
              name: 'userTypes',
              value: filterState.userTypes,
            },
          ]
        : []),
      ...(filterState.assetIds !== -1
        ? [
            {
              name: 'assetIds',
              value: filterState.assetIds,
            },
          ]
        : []),
      {
        name: 'timeToggle',
        value: filterState.timeToggle,
      },
      {
        name: 'typeToggle',
        value: filterState.typeToggle,
      },
      {
        name: 'viewTypeToggle',
        value: filterState.viewTypeToggle,
      },
    ];
    const configuration: IUserConfiguration = {
      [ComponentNamesForUserConfiguration.LaborAssetView]: [
        ...this.defaultUserConfiguration?.filter(
          (config: IComponentConfiguration): boolean => config.name !== 'filters',
        ),
        {
          name: 'filters',
          children: filters,
        },
      ],
    };

    this.hiddenFiltersDefaultSelectionValues = {
      [EFilterDropdownElements.userLevelMultiSelectDropdown]: {
        key: 'id',
        values: _.find(filters, { name: 'userTypes' })?.value || [],
      },
      [EFilterDropdownElements.equipmentMultiSelectDropdown]: {
        key: 'id',
        values: _.find(filters, { name: 'assetIds' })?.value || [],
      },
      [EFilterDropdownElements.userMultiSelectDropdown]: {
        key: 'sourceObjectId',
        values: _.find(filters, { name: 'userIds' })?.value || [],
      },
      ['typeToggle']: {
        key: 'typeToggle',
        values: [_.find(filters, { name: 'typeToggle' })?.value],
      },
    };

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(configuration));
  }

  private performUserConfigurationOperations(state: UserConfigurationStateInterface): void {
    const filterConfiguration: IComponentConfiguration[] = _.find(state.userConfigurationData.LaborAssetViewComponent, {
      name: 'filters',
    })?.children;

    const defaultSelectionValues: DefaultSelectionValuesInterface = {
      [EFilterDropdownElements.siteSingleSelectDropdown]: {
        key: 'id',
        values: _.find(filterConfiguration, { name: 'site' })?.value,
      },
      [EFilterDropdownElements.lineMultiSelectDropdown]: {
        key: 'id',
        values: _.find(filterConfiguration, { name: 'lines' })?.value || [],
      },
      [EFilterDropdownElements.userLevelMultiSelectDropdown]: {
        key: 'id',
        values: _.find(filterConfiguration, { name: 'userTypes' })?.value || [],
      },
      [EFilterDropdownElements.equipmentMultiSelectDropdown]: {
        key: 'id',
        values: _.find(filterConfiguration, { name: 'assetIds' })?.value || [],
      },
      [EFilterDropdownElements.userMultiSelectDropdown]: {
        key: 'sourceObjectId',
        values: _.find(filterConfiguration, { name: 'userIds' })?.value || [],
      },
      ['timeToggle']: {
        key: 'timeToggle',
        values: [_.find(filterConfiguration, { name: 'timeToggle' })?.value],
      },
      ['typeToggle']: {
        key: 'typeToggle',
        values: [_.find(filterConfiguration, { name: 'typeToggle' })?.value],
      },
      ['viewTypeToggle']: {
        key: 'viewTypeToggle',
        values: [_.find(filterConfiguration, { name: 'viewTypeToggle' })?.value],
      },
    };

    const filtersToReset: DefaultSelectionValuesInterface = {};

    this.filterNamesToReset.forEach((filterName: string): void => {
      Object.assign(filtersToReset, { [filterName]: defaultSelectionValues[filterName] });
    });

    this.hiddenFiltersDefaultSelectionValues = filtersToReset;

    combineLatest([
      this.store.select('siteFilter').pipe(filter((value: FilterSiteState) => !value.isLoading && value.isLoaded)),
      this.store.select('lineFilter').pipe(filter((value: FilterLineState) => !value.isLoading && value.isLoaded)),
      this.store
        .select('sourceObjectFilter')
        .pipe(filter((value: SourceObjectFilterState) => !value.isLoading && value.isLoaded)),
      this.store
        .select('equipmentFilter')
        .pipe(filter((value: FilterEquipmentState) => !value.isLoading && value.isLoaded)),
    ])
      .pipe(take(1))
      .subscribe((): void => {
        this.filterHelperService.setDefaultSiteAndPushSubject(
          this.defaultDropdownSelectionSubject,
          Object.assign(defaultSelectionValues, { skipNextUpdate: true }),
        );

        this.store.dispatch(new AppActions.HideLoader());
      });
  }

  public onKeyUpSearchBox(searchText: string): void {
    const search: string = searchText.trim().toLowerCase();

    this.filteredLaborAssetViewData = this.laborAssetViewData$.filter(
      (row: ILaborAssetViewData): boolean => !!row.fullName.trim().toLowerCase().includes(search),
    );
  }

  public onApplyClick(data: LaborAssetViewActions.SetTableSettings): void {
    const headers: ITableHeader[] = [
      ...(this.isLaborAssetViewTableCheckboxVisible
        ? [
            {
              value: null,
              name: '',
              sortable: false,
              width: EColumnWidth.checkbox,
            },
          ]
        : []),
    ];
    const widgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] = [];

    (data.payload || []).forEach((item: TabRowInterface | WidgetConfigurationInterface<EWidgetType.KPI_CARD>): void => {
      if (item?.selected) {
        switch (item?.type) {
          case PageConfigurationTypes.TABLE:
            const pushItem: TabRowInterface = {
              ...(item as TabRowInterface),
              name: (item as TabRowInterface).title,
              value: (item as TabRowInterface)?.value || item.name,
            };

            headers.push(pushItem as ITableHeader);
            break;
          case PageConfigurationTypes.WIDGET:
            widgets.push(item as WidgetConfigurationInterface<EWidgetType.KPI_CARD>);
            break;
        }
      }
    });

    this.laborAssetViewDatatableHeaders$ = this.laborAssetViewDatatableHeaders = _.cloneDeep(headers);
    this.laborAssetViewWidgets$ = widgets;

    if (data.setAsDefault) {
      this.onPageConfigurationSetAsDefault();
    }
  }

  private onPageConfigurationSetAsDefault(): void {
    let selectedColumns: TabRowInterface[] = [];
    this.store.dispatch(new AppActions.ShowTopLoader());

    this.store
      .select('laborAssetViewStore')
      .pipe(take(1))
      .subscribe((state: ILaborAssetViewState): void => {
        selectedColumns = state.tableSettings;
      });

    const configuration: IUserConfiguration = {
      [ComponentNamesForUserConfiguration.LaborAssetView]: [
        ...this.defaultUserConfiguration?.filter(
          (config: IComponentConfiguration): boolean => ['selectedColumns'].indexOf(config.name) === -1,
        ),
        {
          name: 'selectedColumns',
          value: selectedColumns.reduce(
            (filteredData, column: TabRowInterface) => [
              ...filteredData,
              ...(column.selected ? [{ name: column.name, type: column.type }] : []),
            ],
            [],
          ),
        },
      ],
    };

    this.store.dispatch(new UserConfigurationActions.UpdateUserConfigurationLoading(configuration));
  }

  private reloadData(): void {
    this.store.dispatch(new LaborAssetViewActions.LaborAssetViewDataLoading(this.currentFilters));
  }

  private setFiltersAndPageTitle(): void {
    if (!this.selectedSite) {
      return;
    }

    this.filterCardHiddenIds = [];

    const pageMode: ELaborAssetViewDataType = LaborAssetViewService.pageModeSubject.getValue();

    if (pageMode === ELaborAssetViewDataType.ALL) {
      this.store.dispatch(
        new AppActions.ChangePageHeader({
          title: this.translate.instant('pageTitles.labor_asset_view'),
          icon: 'fas fa-users',
        }),
      );

      this.defaultDropdownSelectionSubject.next(
        Object.assign({}, this.hiddenFiltersDefaultSelectionValues ?? {}, { skipNextUpdate: true })
      );
    } else if (pageMode === ELaborAssetViewDataType.LABOR) {
      this.store.dispatch(
        new AppActions.ChangePageHeader({
          title: this.translate.instant('pageTitles.labor_view'),
          icon: 'fas fa-users',
        }),
      );

      this.filterCardHiddenIds = ['typeToggle', EFilterDropdownElements.equipmentMultiSelectDropdown];
    } else if (pageMode === ELaborAssetViewDataType.ASSET) {
      this.store.dispatch(
        new AppActions.ChangePageHeader({
          title: this.translate.instant('pageTitles.asset_view'),
          icon: 'fas fa-cube',
        }),
      );

      this.filterCardHiddenIds = [
        'typeToggle',
        EFilterDropdownElements.userMultiSelectDropdown,
        EFilterDropdownElements.userLevelMultiSelectDropdown,
      ];
    }

    if (!this.filterCardHiddenIds.length) {
      this.resetFilterCardHiddenItems.next();
    }

    this.store.dispatch(new PageHeaderActions.UpdateFilterBarVisibility(this.isFilterBarVisible$));
  }

  private getDataTableHeaders(): ITableHeader[] {
    const pageMode: ELaborAssetViewDataType = LaborAssetViewService.pageModeSubject.getValue();

    if (pageMode === ELaborAssetViewDataType.LABOR) {
      return this.laborAssetViewService.getLaborAssetViewHeaders(ELaborAssetViewDataType.LABOR);
    } else if (pageMode === ELaborAssetViewDataType.ASSET) {
      return this.laborAssetViewService.getLaborAssetViewHeaders(ELaborAssetViewDataType.ASSET);
    }

    return this.laborAssetViewService.getLaborAssetViewHeaders(ELaborAssetViewDataType.ALL);
  }

  private getKpiWidgets(): WidgetConfigurationInterface<EWidgetType.KPI_CARD>[] {
    const pageMode: ELaborAssetViewDataType = LaborAssetViewService.pageModeSubject.getValue();

    if (pageMode === ELaborAssetViewDataType.LABOR) {
      return this.laborAssetViewService.getLaborAssetViewWidgets(ELaborAssetViewDataType.LABOR);
    } else if (pageMode === ELaborAssetViewDataType.ASSET) {
      return this.laborAssetViewService.getLaborAssetViewWidgets(ELaborAssetViewDataType.ASSET);
    }

    return this.laborAssetViewService.getLaborAssetViewWidgets(ELaborAssetViewDataType.ALL);
  }

  private setPageConfiguration(): void {
    this.store.dispatch(
      new LaborAssetViewActions.SetPageConfiguration({
        headers: this.getDataTableHeaders(),
        widgets: this.getKpiWidgets(),
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });

    LaborAssetViewService.pageModeSubject.next(ELaborAssetViewDataType.ALL);
  }
}
