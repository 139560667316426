import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Line } from '../../../../store/line/model';
import { KpiTargetsStateInterface } from '../../../../store/settings/kpi-targets/kpi-targets.model';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { User } from '../../../../store/user/model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mysqlTimestampFormat } from '../../../../shared/helper/date';
import { HelperService } from '../../../../shared/service/helper.service';
import { IAddOeeToNonActivityHours } from '../home-metrics.model';
import { IOeeCalculationResult } from '../../../../store/reports/work-order-performance/work-order-performance.model';

@Component({
  selector: 'app-work-order-oee-metric-twelve-hours',
  templateUrl: './work-order-oee-metric-twelve-hours.component.html',
})
export class WorkOrderOeeMetricTwelveHoursComponent implements OnInit {
  private readonly subscriptions: Subscription[] = [];

  public chartMetricTitle = this.translate.instant('kpiCards.metrics.work_order_oee_twelve_hours');
  public data: string[] = [];
  public percentageData: string[] = [];
  public averageOee: string = null;
  public target: string = '0';
  private siteDecimalScaleLimit$: number;
  private timeZone: string;
  private dataForTwelveHours: IAddOeeToNonActivityHours[] = [];
  private maxValue: string = '0';

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
        this.timeZone = state.timezone;
      }),

      this.store.select('kpiTargetsStore').subscribe((state: KpiTargetsStateInterface) => {
        if (!state.bulkEditKpiTargetLoading && state.kpiTargetsLoaded) {
          this.target = _.isNil(state.kpiTargetsData?.data[0]?.oeeTarget)
            ? null
            : state.kpiTargetsData?.data[0]?.oeeTarget;
        }
      }),

      this.store.select('line').subscribe((state: Line) => {
        this.data = [];
        this.dataForTwelveHours = [];
        this.maxValue = '0';
        this.averageOee = _.isNil(state.quantities.oeeValueOf12Hours) ? '0' : state.quantities.oeeValueOf12Hours;
        this.averageOee = this.decimalHelper.multiply(this.averageOee, '100');
        this.averageOee = this.decimalHelper.toFixedValue(this.averageOee, this.siteDecimalScaleLimit$);

        const hourly = HelperService.cloneDeep(state.quantities.hourlyOee);
        this.addOeeToNonActivityHours(hourly);

        this.percentageData = this.data.map((item: string) =>
          this.decimalHelper.divide(this.decimalHelper.multiply(item, '100'), this.maxValue),
        );
      }),
    );
  }

  public addOeeToNonActivityHours(hourly:IOeeCalculationResult[]): void {
    for (let i = 0; i < 12; i = i + 1) {
      const date = moment().tz(this.timeZone).startOf('hours').subtract(i, 'hour').format(mysqlTimestampFormat);
      this.dataForTwelveHours.push({ date, value: '0' });
    }

    this.dataForTwelveHours.forEach((data: IAddOeeToNonActivityHours) => {
      const selectedHour = hourly.find((oee:IOeeCalculationResult) => oee.intervalStartDate === data.date);
      const hourlyValue: string = _.isNil(selectedHour)
        ? data.value
        : this.decimalHelper.multiply(selectedHour.overallOEE, '100');
      this.maxValue = this.decimalHelper.max(this.maxValue, hourlyValue) ? this.maxValue : hourlyValue;

      this.data.push(hourlyValue);
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
