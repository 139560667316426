import {
  Component,
  EventEmitter,
  InjectionToken,
  Injector,
  Input, NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { IHomeMetricSetItem } from '../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import * as HomeActions from '../../../store/home/home.actions';
import {
  COMPONENT_TYPE_TOKEN,
  DASHBOARD_MODE,
} from './common-phase-duration-metric/common-phase-duration-metric.component';
import Timer = NodeJS.Timer;
import { take, takeUntil } from 'rxjs/operators';
import { Line } from '../../../store/line/model';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../../../store/user/model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  EComponentType,
  HomeMetrics,
  IHomeMetricSiteLineInfo,
  IHomeScreenMetric,
  ISimplifiedActivityCard,
  ISimplifiedWorkOrderCard,
  ISiteLineName,
} from './home-metrics.model';
import { IMeta, IMiniMetricMeta } from '../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { IKpiCards } from '../../../shared/component/kpi-cards-information/kpi-cards-information.model';
import { OnDestroyDecorator } from '../../../shared/decorator/on-destroy-decorator';
import * as KpiTargetsActions from '../../../store/settings/kpi-targets/kpi-targets.actions';
import * as moment from 'moment';
import { KpiTargetTableQueryParams } from '../../../store/settings/kpi-targets/kpi-targets.model';
import * as _ from 'lodash';
import { HelperService } from '../../../shared/service/helper.service';
import { HomeStateInterface } from '../../../store/home/home.model';
import { HomePageDisplaySettingsUtilities } from '../../settings/home-page-display-settings/home-page-display-settings.utilities';
import { ComponentColors } from '../../../shared/service/color/color.model';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { activityColors } from '../../../shared/helper/app-helper';
import { LineViewComponent } from '../../global-view/site-view/line-view/line-view.component';
import { Router } from '@angular/router';
import * as UserActions from '../../../store/user/actions';
import { ofType } from '@ngrx/effects';
import * as HomePageDisplaySettingsActions from '../../../store/settings/home-page-display-settings/home-page-display-settings.actions';
import { ISemiManualCountButtonCardState } from '../../../store/station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.model';
import { EHomeMetrics } from '../../settings/home-page-display-settings/home-page-display-settings.constants';
import { IBaseSensorInfo, ITemperatureSensorInfo } from './home-mini-metrics/home-mini-metrics.model';
export const PROPERTIES = new InjectionToken('properties');

@OnDestroyDecorator
@Component({
  selector: 'app-home-metrics',
  templateUrl: './home-metrics.component.html',
  styleUrls: ['./home-metrics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeMetricsComponent implements OnInit, OnDestroy {
  @Input() dashboardMode: boolean = false;
  @Input() lineViewMode: boolean = false;
  @Input() plannedDownTimeActivityColorsStream: ComponentColors;
  @Input() set siteLineInfoSetter(siteLineInfo: IHomeMetricSiteLineInfo) {
    this.setSiteLineInfo(siteLineInfo);
  }
  @Input() set activityInfoSetter(activityInfo: ISimplifiedActivityCard) {
    this.setActivityInfo(activityInfo);
  }
  @Input() set workOrderInfoSetter(workOrderInfo: ISimplifiedWorkOrderCard) {
    this.setWorkOrderInfo(workOrderInfo);
  }
  @Input() public isMiniMetricsEnabled: boolean = false;
  @Output() dashboardModeChange = new EventEmitter<boolean>();

  private readonly noActivityBackground: string = '#FAF9F9';

  public homeSubscription: Subscription;
  public userSubscription: Subscription;
  public semiManualCountSubscription: Subscription;
  public siteLineChangeSubscription: Subscription;
  public updateUserSiteLineSubscription: Subscription;
  public metricSetItemsArray: IHomeScreenMetric[] = [];
  public metricSetItemsArrayWithProperties: IHomeScreenMetric[] = [];
  public kpiCards: IKpiCards[] = [];
  public sensorDataXHRInterval: any;
  public homeScreenMetrics: HomeMetrics = HomePageDisplaySettingsUtilities.getComponentsOfHomeScreenMetrics(
    this.dashboardMode,
  );
  public phaseLoading: boolean = false;
  public phaseCommentLoading: boolean = false;
  public phaseDurationKeyArray: string[] = ['preRunPhaseDuration', 'runPhaseDuration', 'postRunPhaseDuration'];
  private phaseCommentKeyArray: string[] = ['preRunPhaseDuration', 'runPhaseDuration', 'postRunPhaseDuration'];
  public homeScreenMetricTooltipTitle: string = this.translate.instant(
    'homeScreenMetricCard.tooltip.homeScreenMetricTooltipTitle',
  );
  public homeScreenMetricTooltipText: string = this.translate.instant(
    'homeScreenMetricCard.tooltip.homeScreenMetricTooltipText',
  );
  public modalOptions: NgbModalOptions = {
    windowClass: 'scw-modal-md',
    keyboard: false,
    backdrop: 'static',
  };
  public activityInfo: ISimplifiedActivityCard;
  public workOrderInfo: ISimplifiedWorkOrderCard;
  public siteLineName: ISiteLineName;
  public homeMetricsLoaded: boolean = false;
  public isSiteLineChanging: boolean = true;
  public temperatureSensors: ITemperatureSensorInfo[] = [];
  public humiditySensors: IBaseSensorInfo[] = [];

  private injectors = {};
  private phaseCommentTimer: Timer;
  private lineId$: number;
  private propertiesArray: IMeta[] = [];
  private siteLineInfo?: IHomeMetricSiteLineInfo = null;
  private isFetchQuantitiesNeeded: boolean = false;

  protected cardClass: string = '';
  public productTableId: number = null;

  constructor(
    private store: Store<OeeAppState>,
    public injector: Injector,
    private translate: TranslateService,
    private readonly ngbModal: NgbModal,
    private readonly ngZone: NgZone,
    private readonly actionsSubject: ActionsSubject,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new HomePageDisplaySettingsActions.GetProductUnits());
    this.cardClass = `${this.lineViewMode ? ' line-view-min-height overflow-hidden' : ''}`;

    if (!this.siteLineInfo) {
      this.userSubscription = this.store.select('user').subscribe((state: User) => {
        if (this.lineId$ !== state.lineId) {
          this.injectors = {};
          this.metricSetItemsArrayWithProperties = [];
          this.lineId$ = state.lineId;
        }

        if (state.isUserLoaded) {
          this.loadKPITargets(state.siteId);
        }
      });
    }

    this.homeSubscription = this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
      this.homeMetricsLoaded =
        state.homeMetricSetItemsLoaded && !state.homeInformationLoading && state.homeInformationLoaded;

      if (state.homeMetricLoadingComplete) {
        this.isSiteLineChanging = false;
      }

      if (this.homeMetricsLoaded) {
        this.metricSetItemsArray = [];
        this.metricSetItemsArrayWithProperties = [];

        const aMinuteInMS = 60000;

        const setItems = HelperService.cloneDeep(state.homeMetricSetItems)
          .filter((metricSetItem: IHomeMetricSetItem) => metricSetItem.metric !== EHomeMetrics.MINI_METRICS)
          .slice(0, 6);
        let phaseCommentIsAvailable: boolean = false;

        this.prepareMiniMetrics(state.homeMetricSetItems);

        this.metricSetItemsArray = setItems.map((metricSetItem: IHomeMetricSetItem, index: number) => {
          const validMetric: string = Object.keys(this.homeScreenMetrics).find(
            (key: string) => this.homeScreenMetrics[key].key === metricSetItem.metric,
          );

          if (!this.phaseLoading && this.phaseDurationKeyArray.includes(validMetric)) {
            this.phaseLoading = true;

            this.store.dispatch(new HomeActions.PhaseDurationsLoading(this.lineId$));
          }

          if (this.phaseCommentKeyArray.includes(validMetric)) {
            phaseCommentIsAvailable = true;
            if (!this.phaseCommentLoading) {
              this.phaseCommentLoading = true;
              let wOTableId;
              this.store
                .select('line')
                .pipe(take(1))
                .subscribe((lineState: Line) => {
                  wOTableId = lineState.wOTableId;
                  if (wOTableId && wOTableId !== '0') {
                    this.store.dispatch(new HomeActions.PhaseCommentsLoading(wOTableId));
                  }
                });

              if (this.phaseCommentTimer) {
                clearInterval(this.phaseCommentTimer);
              }

              this.ngZone.runOutsideAngular(() => {
                this.phaseCommentTimer = setInterval(() => {
                  this.ngZone.run(() => {
                    if (wOTableId) {
                      this.store.dispatch(new HomeActions.PhaseCommentsLoading(wOTableId));
                    }
                  });
                }, aMinuteInMS);
              });

            }
          }

          if (validMetric) {
            if (metricSetItem.homeMetricSetProperties) {
              this.propertiesArray[index] = metricSetItem.homeMetricSetProperties?.meta as IMeta;
            }

            return this.homeScreenMetrics[validMetric];
          }
        });

        if (this.phaseCommentTimer && !phaseCommentIsAvailable) {
          clearInterval(this.phaseCommentTimer);
        }

        if (this.metricSetItemsArray.length === 0) {
          this.metricSetItemsArray = Object.values(this.homeScreenMetrics).slice(0, 6);
        }

        if (!this.sensorDataXHRInterval && !this.lineViewMode) {
          this.ngZone.runOutsideAngular(() => {
            this.sensorDataXHRInterval = setInterval(() => {
              this.ngZone.run(() => {
                this.store.dispatch(new HomeActions.GetQuantityFromSensor(false, this.lineId$));
              });
            }, aMinuteInMS);
          });
        }

        if (!state.homeMetricSetItemsLoading) {
          for (let i = 0; i < this.metricSetItemsArray.length; i = i + 1) {
            if (this.metricSetItemsArrayWithProperties.length === this.metricSetItemsArray.length) {
              return;
            }

            if (this.propertiesArray[i]) {
              this.metricSetItemsArrayWithProperties.push({
                ...this.metricSetItemsArray[i],
                homeMetricSetProperties: this.propertiesArray[i],
              });
            } else {
              this.metricSetItemsArrayWithProperties.push(this.metricSetItemsArray[i]);
            }
          }

          this.kpiCards = this.metricSetItemsArrayWithProperties.map((metric: IHomeScreenMetric) => {
            return {
              id: metric.key,
              name: this.translate.instant(`kpiCards.metrics.${metric.key}`),
              homeMetricSetProperties: _.isEmpty(metric.homeMetricSetProperties)
                ? null
                : metric.homeMetricSetProperties,
            };
          });
        }

        if (
          state.line.workOrderCard.productTableId !== null &&
          state.line.workOrderCard.unitId !== null &&
          this.productTableId !== state.line.workOrderCard.productTableId
        ) {
          this.productTableId = HelperService.cloneDeep(state.line.workOrderCard.productTableId);

          this.store.dispatch(
            new HomePageDisplaySettingsActions.GetProductUnitConversions(
              this.productTableId,
              HelperService.cloneDeep(state.line.workOrderCard.unitId),
            ),
          );
        }
      }

      if (state.homeMetricSetItemsLoading) {
        this.phaseLoading = false;
        this.phaseCommentLoading = false;

        if (this.sensorDataXHRInterval) {
          clearInterval(this.sensorDataXHRInterval);

          this.sensorDataXHRInterval = null;
        }
      }
    });

    const semiManualCountDestroySubject: Subject<void> = new Subject<void>();

    this.semiManualCountSubscription = this.store
      .select('semiManualCountButtonCardStore')
      .pipe(takeUntil(semiManualCountDestroySubject))
      .subscribe((state: ISemiManualCountButtonCardState) => {
        this.isFetchQuantitiesNeeded =
          this.isFetchQuantitiesNeeded || (state.isSendVirtualCounts && !state.isSendVirtualCountsCompleted);

        if (state.isSendVirtualCountsCompleted && !state.isSendVirtualCounts) {
          if (this.isFetchQuantitiesNeeded) {
            this.store.dispatch(new HomeActions.GetQuantityFromSensor(false, this.lineId$));
            this.isFetchQuantitiesNeeded = false;
          }

          semiManualCountDestroySubject.next();
        }
      });

    if (this.lineViewMode) {
      this.siteLineChangeSubscription = LineViewComponent.siteLineChangeSubject.subscribe(
        (isSiteLineChanging: boolean): void => {
          this.isSiteLineChanging = isSiteLineChanging;
        },
      );
    }

    this.updateUserSiteLineSubscription = this.actionsSubject
      .pipe(ofType(UserActions.UPDATE_CURRENT_USER_LOADED))
      .subscribe((payload: UserActions.UpdateCurrentUserLoaded) => {
        if (payload.navigateToHomeAfterwards) {
          const url: string = this.router.serializeUrl(this.router.createUrlTree(['/home']));

          window.open('#' + url, '_blank');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.phaseCommentTimer) {
      clearInterval(this.phaseCommentTimer);
    }

    if (this.sensorDataXHRInterval) {
      clearInterval(this.sensorDataXHRInterval);
    }

    if (this.homeSubscription) {
      this.homeSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.semiManualCountSubscription) {
      this.semiManualCountSubscription.unsubscribe();
    }

    if (this.siteLineChangeSubscription) {
      this.siteLineChangeSubscription.unsubscribe();
    }

    if (this.updateUserSiteLineSubscription) {
      this.updateUserSiteLineSubscription.unsubscribe();
    }
  }

  getInjector(componentType: EComponentType, homeMetricSetProperties: IMeta | undefined, index): Injector {
    const injectKey = componentType + index;
    let inject = this.injectors[injectKey];

    if (!inject) {
      inject = Injector.create({
        providers: [
          { provide: COMPONENT_TYPE_TOKEN, useValue: componentType },
          { provide: DASHBOARD_MODE, useValue: this.dashboardMode },
          { provide: PROPERTIES, useValue: homeMetricSetProperties },
        ],
        parent: this.injector,
        name: componentType,
      });
      this.injectors[injectKey] = inject;
    }

    return inject;
  }

  public openKpiCardInformationModal(content: TemplateRef<any>): void {
    this.ngbModal.open(content, this.modalOptions);
  }

  private loadKPITargets(siteID: number): void {
    const kpiTargetTableQuery: KpiTargetTableQueryParams = {
      page: 1,
      pageSize: 10,
      siteId: [siteID],
      lineId: [this.lineId$],
      targetPeriod: { startDate: moment().startOf('month'), endDate: moment().endOf('month') },
      search: '',
    };

    this.store.dispatch(
      new KpiTargetsActions.KpiTargetsDataLoading(HelperService.cloneDeep(kpiTargetTableQuery), true),
    );
  }

  private setSiteLineInfo(siteLineInfo: IHomeMetricSiteLineInfo): void {
    if (!_.isEqual(siteLineInfo, this.siteLineInfo)) {
      this.siteLineInfo = siteLineInfo;

      if (this.lineId$ !== this.siteLineInfo.lineID) {
        this.injectors = {};
        this.metricSetItemsArrayWithProperties = [];
        this.lineId$ = this.siteLineInfo.lineID;
      }

      if (this.lineId$ && this.siteLineInfo.siteID) {
        this.loadKPITargets(this.siteLineInfo.siteID);
      }

      this.siteLineName = {
        lineTitle: this.siteLineInfo.lineTitle,
        siteName: this.siteLineInfo.siteName,
      };
    }
  }

  private setActivityInfo(activityInfo: ISimplifiedActivityCard): void {
    if (!_.isEqual(activityInfo, this.activityInfo)) {
      this.activityInfo = { ...activityInfo, activityName: activityInfo?.activityName ?? ' - ' };

      this.activityInfo.backgroundColour = this.activityInfo.activityType
        ? activityColors(this.activityInfo.activityType, {
            [ActivityTypes.DOWN_TIME_PLANNED]: this.plannedDownTimeActivityColorsStream?.background,
          })
        : this.noActivityBackground;
    }
  }

  private setWorkOrderInfo(workOrderInfo: ISimplifiedWorkOrderCard): void {
    if (!_.isEqual(workOrderInfo, this.workOrderInfo)) {
      this.workOrderInfo = {
        ...workOrderInfo,
        workOrderName: workOrderInfo?.workOrderName ?? ' - ',
      };
      this.workOrderInfo.backgroundColour = '#FAF9F9';
    }
  }

  public goToHome(): void {
    if (this.siteLineInfo.siteID !== null && this.siteLineInfo.lineID !== null) {
      this.store.dispatch(
        new UserActions.UpdateCurrentUser(
          this.siteLineInfo.siteID,
          this.siteLineInfo.lineID,
          this.router.url !== '/home',
        ),
      );
      this.store.dispatch(new HomeActions.ResetPhaseDurationOnSiteLineSelection());
    }
  }

  private prepareMiniMetrics(homeMetricSetItems: IHomeMetricSetItem[]): void {
    this.temperatureSensors = [];
    this.humiditySensors = [];

    const miniMetrics: IHomeMetricSetItem | undefined = homeMetricSetItems.find(
      (metricSetItem: IHomeMetricSetItem) => metricSetItem.metric === EHomeMetrics.MINI_METRICS,
    );

    if (!miniMetrics) {
      return;
    }

    const miniMetricsMeta: IMiniMetricMeta[] = (miniMetrics.homeMetricSetProperties?.meta as IMiniMetricMeta[]) ?? [];
    const lineMiniMetricsMeta: IMiniMetricMeta | undefined = miniMetricsMeta.find(
      (meta: IMiniMetricMeta) => meta?.lineId === this.lineId$,
    );

    if (!lineMiniMetricsMeta) {
      return;
    }

    this.temperatureSensors = lineMiniMetricsMeta.miniMetrics?.temperature;
    this.humiditySensors = lineMiniMetricsMeta.miniMetrics?.humidity;
  }
}
