import { NgModule } from '@angular/core';
import { MomentDatePipe } from 'src/app/standalone/pipes/moment-date.pipe';
import { HomeComponent } from './home.component';
import { SharedModule } from '../../shared/shared.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ChartModule } from 'angular2-chartjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { CICOComponent } from './cico/cico.component';
import { ActivityCardsComponent } from './activity-cards/activity-cards.component';
import { WorkOrderComponent } from './work-order/work-order.component';
import { FinalizerButtonComponent } from './work-order/finalizer-button/finalizer-button.component';
import { JoyrideModule } from 'ngx-joyride';
import { CICOModule } from './cico/cico.module';
import { BatchCloseComponent } from './work-order/batch-close/batch-close.component';
import { HomeMetricsComponent } from './home-metrics/home-metrics.component';
import { WorkOrderDurationMetricComponent } from './home-metrics/work-order-duration-metric/work-order-duration-metric.component';
import { ActivityDurationMetricComponent } from './home-metrics/activity-duration-metric/activity-duration-metric.component';
import { WorkOrderCountBadgesComponent } from './home-metrics/work-order-count-badges/work-order-count-badges.component';
import { CommonPhaseDurationMetricComponent } from './home-metrics/common-phase-duration-metric/common-phase-duration-metric.component';
import { SelectModule } from 'ng-select';
import { QuantityWithCommentMetricComponent } from './home-metrics/quantity-with-comment-metric/quantity-with-comment-metric.component';
import { SpeedMetricComponent } from './home-metrics/speed-metric/speed-metric.component';
import { WorkOrderOeeMetricComponent } from './home-metrics/work-order-oee-metric/work-order-oee-metric.component';
import { WorkOrderOeeMetricSixHoursComponent } from './home-metrics/work-order-oee-metric-six-hours/work-order-oee-metric-six-hours.component';
import { WorkOrderOeeMetricTwelveHoursComponent } from './home-metrics/work-order-oee-metric-twelve-hours/work-order-oee-metric-twelve-hours.component';
import { StationHomeComponent } from './station-home/station-home.component';
import { CommentFeedButtonComponent } from '../../standalone/comment-feed/comment-feed-button/comment-feed-button.component';
import { CountMetricComponent } from './home-metrics/count-metric/count-metric.component';
import { AngularFittextModule } from 'angular-fittext';
import { ActivitiesTotalDurationMetricComponent } from './home-metrics/activities-total-duration-metric/activities-total-duration-metric.component';
import { ShiftOeeMetricComponent } from './home-metrics/shift-oee-metric/shift-oee-metric.component';
import { LaborAssetHomeModule } from './labor-asset-home/labor-asset-home.module';
import { StationHomeKpiCardComponent } from './station-home/station-home-kpi-card/station-home-kpi-card.component';
import { MiniMetricsComponent } from './home-metrics/mini-metrics/mini-metrics.component';
import { HomeMiniMetricsComponent } from './home-metrics/home-mini-metrics/home-mini-metrics.component';
import { ManageCountModalComponent } from '../../standalone/modals/manage-count-modal/manage-count-modal.component';

@NgModule({
  imports: [
    MomentDatePipe,
    SharedModule,
    Ng2GoogleChartsModule,
    ChartModule,
    NgxChartsModule,
    OwlMomentDateTimeModule,
    JoyrideModule.forRoot(),
    CICOModule,
    SelectModule,
    CommentFeedButtonComponent,
    AngularFittextModule,
    LaborAssetHomeModule,
    StationHomeKpiCardComponent,
    MiniMetricsComponent,
    HomeMiniMetricsComponent,
    ManageCountModalComponent,
  ],
  declarations: [
    HomeComponent,
    CICOComponent,
    ActivityCardsComponent,
    WorkOrderComponent,
    FinalizerButtonComponent,
    BatchCloseComponent,
    HomeMetricsComponent,
    WorkOrderDurationMetricComponent,
    ActivityDurationMetricComponent,
    WorkOrderCountBadgesComponent,
    CommonPhaseDurationMetricComponent,
    QuantityWithCommentMetricComponent,
    SpeedMetricComponent,
    WorkOrderOeeMetricComponent,
    WorkOrderOeeMetricSixHoursComponent,
    WorkOrderOeeMetricTwelveHoursComponent,
    StationHomeComponent,
    CountMetricComponent,
    ActivitiesTotalDurationMetricComponent,
    ShiftOeeMetricComponent,
  ],
  entryComponents: [
    ActivityDurationMetricComponent,
    CommonPhaseDurationMetricComponent,
    QuantityWithCommentMetricComponent,
    WorkOrderDurationMetricComponent,
    WorkOrderCountBadgesComponent,
    SpeedMetricComponent,
    WorkOrderOeeMetricComponent,
    WorkOrderOeeMetricSixHoursComponent,
    WorkOrderOeeMetricTwelveHoursComponent,
    CountMetricComponent,
    ActivitiesTotalDurationMetricComponent,
  ],
  exports: [HomeMetricsComponent, HomeComponent],
})
export class HomeModule {}
